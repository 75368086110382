import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Snackbar,
  Button,
  createStyles,
  Theme,
  withStyles,
  Typography,
  Tooltip,
  Card,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PersonIcon from "@material-ui/icons/Person";
import { Alert } from "@material-ui/lab";
import api from "../services/api";
import { ReportProblemRounded } from "@material-ui/icons";

interface TableProps {
  location?: any;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F4F7FC",
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: "115px",
    top: "20px",
    left: "15px",
  },
  dialogVencimento: {
    position: "relative",
    margin: "0 auto",
    width: "450px",
    maxWidth: "unset",
    minWidth: "450px",
  },
  dialog: {
    position: "relative",
    margin: "0 auto",
    width: "200px",
    maxWidth: "unset",
    minWidth: "200px",
  },
  typography: {
    position: "relative",
    textalign: "left",
    fontSize: "14px",
    fontWeight: 400,
    letterSpacing: "0px",
    opacity: 1,
    width: "140px",
    height: "19px",
    top: "7px",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonBottom: {
    marginTop: "1.5%",
    marginLeft: "1%",
  },
  backdrop: {
    zIndex: 700,
    color: "#fff",
  },
  tableRow: {
    textOverflow: "ellipsis",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D6E4FF",
    },
  },
  tableHead: {
    background: "#F4F7FC",
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: "0",
    width: "100%",
    verticalAlign: "left",
    opacity: "0.94",
    height: "20px",
  },
  cell: {
    font: "normal normal caption 14px/19px Open Sans",
    textAlign: "left",
    display: "table-cell",
    padding: "16px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    letterSpacing: "0.01071em",
    verticalAlign: "inherit",
    textOverflow: "ellipsis",
  },
  cellValores: {
    font: "normal normal caption 14px/19px Open Sans",
    display: "table-cell",
    padding: "16px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    letterSpacing: "0.01071em",
    verticalAlign: "inherit",
    textOverflow: "ellipsis",
  },
  body: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "separate",
    boxSizing: "border-box",
    textIndent: "initial",
    borderColor: "grey",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#00336A",
    textTransform: "capitalize",
    opacity: "1",

    "&:td:nth-child(odd)": {
      backgroundColor: "#ffffff",
    },
    "&:td:nth-child(even)": {
      backgroundColor: "#cccccc",
    },
  },
  button: {
    position: "relative",
    backgroundColor: "#8EC325",
    display: "inline-flex",
    top: "20px",
    float: "right",
    right: "30px",
    width: "180px",
    height: "40px",
    font: "normal normal caption 14px/19px Open Sans",
    letterSpacing: "0px",
    textTransform: "capitalize",
    marginCottom: "30px",
    "&:hover": {
      backgroundColor: "#80b021",
      color: "#FFF",
      opacity: "5",
    },
  },
  cellHeader: {
    font: "caption",
    textTransform: "uppercase",
    fontWeight: "bolder",
    fontSize: "12px",
    letterSpacing: "0px",
    color: "#7C8FB3",
  },
  header: {
    font: "caption",
    textTransform: "uppercase",
    fontWeight: "bolder",
    marginLeft: "25px",
    fontSize: "12px",
    letterSpacing: "0px",
  },
  headerTitle: {
    font: "caption",
    textTransform: "uppercase",
    fontWeight: "bolder",
    marginLeft: "25px",
    fontSize: "14px",
    letterSpacing: "0px",
    marginTop: "2%",
    marginBottom: "1%",
    color: "#1662B5",
  },
  alignIcon: {
    position: "relative",
    top: "7px",
  },
  alignIconAlert: {
    position: "relative",
    top: "5px",
    heigth: "17px",
  },
  line: {
    display: "flex",
    flexFlow: "row wrap",
    marginBottom: "0.5%",
    marginTop: "3%",
  },
  textfield: {
    width: "15.5%",
    fontSize: "11px",
    marginTop: "-1%",
  },
  card: {
    position: "relative",
    top: "130px",
    margin: "0 auto",
    width: "1180px",
    bottom: "70px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "4px",
    opacity: 1,
    zIndex: 0,
  },
  cardBottom: {
    position: "relative",
    top: "160px",
    margin: "0 auto",
    width: "1180px",
    bottom: "70px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "4px",
    opacity: 1,
    zIndex: 0,
  },
  buttonsDiv: {
    float: "right",
    marginBottom: "20px",
    marginRight: "18px",
  },
  buttons: {
    color: "#fff",
    backgroundColor: "#1476FC",
    height: "25px",
    fontSize: "11px",
    marginRight: "10px",
  },
  buttonVoltar: {
    color: "#000",
    backgroundColor: "#F1F3F4",
    height: "25px",
    fontSize: "11px",
    marginRight: "10px",
  },
  headerForm: {
    font: "caption",
    textTransform: "uppercase",
    fontWeight: "bolder",
    marginLeft: "20px",
    fontSize: "11px",
    letterSpacing: "0px",
    color: "#7C8FB3",
    marginRight: "5px",
  },
  headerDistribuir: {
    font: "caption",
    textTransform: "uppercase",
    fontWeight: "bolder",
    marginLeft: "20px",
    fontSize: "11px",
    letterSpacing: "0px",
    color: "#7C8FB3",
    marginRight: "5px",
    height: "40px",
  },
  total: {
    font: "caption",
    fontWeight: "bolder",
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#000",
    marginRight: "3%",
    height: "15px",
    margin: "15px",
    textAlign: "right",
  },
  divider: {
    position: "relative",
    backgroundColor: "#F1F3F4",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "2px",
  },
  error: {
    position: "absolute",
    top: "88.5%",
    left: "12.5%",
    letterSpacing: "0px",
    color: "#e60000",
    opacity: 1,
    fontSize: "12px",
  },
  erro: {
    position: "relative",
    top: "-35px",
    left: "12.5%",
    letterSpacing: "0px",
    color: "#e60000",
    opacity: 1,
    fontSize: "12px",
  },
  textFieldOptions: {
    fontSize: "12px",
  },
  typographyButton: {
    fontSize: "11px",
    color: "red",
  },
  buttonDiv: {
    position: "relative",
    top: "185px",
    margin: "0 auto",
    height: "10px",
    width: "998px",
    opacity: 1,
    zIndex: 0,
  },
  iconButton: {
    width: "20px",
    color: "red",
  },
  buttonVoltarDetalhes: {
    backgroundColor: "#e9e9e9",
  },
  tooltipInconsis: {
    color: "red",
  },
}));

const Faturas: React.FC<TableProps> = () => {
  const { uc } = useParams<{ uc: string }>();

  const [faturas, setFaturas] = useState<Record<string, unknown>[]>([]);
  const [dadosUc, setDadosUC] = useState<Record<string, unknown>>({});

  const [valorPrincipal, setValorPrincipal] = useState(0);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState<any>();
  const [loadingReport, setLoadingReport] = useState(false);
  const [count, setCount] = useState(0);
  const [honorarios, setHonorarios] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({});
  const [total, setTotal] = useState(0);
  const [listCheckbox] = React.useState<any>([]);
  const [faturasSelecionadas] = React.useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
    setLoadingReport(true);

    api
      .get(`agencia/${uc}`)
      .then((response) => {
        setDadosUC(response.data.uc);
        setFaturas(response.data.faturas);
      })
      .finally(() => {
        setLoadingReport(false);
      });
  }, [uc]);

  const header = [
    {
      title: (
        <input
          type="checkbox"
          onChange={(event) => selectAll(event.target.checked)}
          checked={checkedAll}
        />
      ),
      value: 0,
    },
    { title: "Referência", value: 1 },
    { title: "Tipo", value: 2 },
    { title: "Vencimento", value: 3 },
    { title: "Valor", value: 4 },
    { title: "Multa", value: 5 },
    { title: "Juros", value: 6 },
    { title: "C. Mon.", value: 7 },
    { title: "Hon.", value: 8 },
    { title: "Devido", value: 9 },
    { title: "Rev", value: 10 },
    { title: "Div. A.", value: 11 },
    { title: "Ajuiz.", value: 12 },
    { title: "Cart.", value: 13 },
    { title: "SPC", value: 14 },
  ];

  let data = faturas.map(
    ({
      idFatura,
      idTipoFat,
      mesRefFat,
      dtaVencimento,
      vlrEmissao,
      idSituProcFatura,
      staDivAtiva,
      descTipoFatura,
      multa,
      juros,
      correcao,
      honorario,
      staEmProtesto,
    }: any) => ({
      id: idFatura,
      idTipoFat: idTipoFat,
      idSituProcFatura: idSituProcFatura,
      mesRefFat: formatReference(mesRefFat),
      abreviacao: descTipoFatura,
      dtaVencimento: formatDateWithoutHours(dtaVencimento),
      vlrEmissao: !vlrEmissao
        ? null
        : vlrEmissao?.toString().includes(".")
        ? Number(vlrEmissao).toFixed(2)
        : vlrEmissao + ".00",
      multa: multa?.toString().includes(".")
        ? Number(multa).toFixed(2)
        : (multa === null ? 0 : multa) + ".00",
      juros: juros?.toString().includes(".")
        ? Number(juros).toFixed(2)
        : (juros === null ? 0 : juros) + ".00",
      cMon: correcao?.toString().includes(".")
        ? Number(correcao).toFixed(2)
        : (correcao === null ? 0 : correcao) + ".00",
      honorario: honorario?.toString().includes(".")
        ? Number(honorario).toFixed(2)
        : (honorario === null ? 0 : honorario) + ".00",
      valorDevido: (
        Number(vlrEmissao) +
        Number(honorario) +
        Number(multa) +
        Number(juros) +
        Number(correcao)
      )
        ?.toString()
        .includes(".")
        ? (
            Number(vlrEmissao) +
            Number(honorario) +
            Number(multa) +
            Number(juros) +
            Number(correcao)
          ).toFixed(2)
        : (Number(vlrEmissao) +
            Number(multa) +
            Number(honorario) +
            Number(juros) +
            Number(correcao) ===
          null
            ? 0
            : Number(vlrEmissao) +
              Number(honorario) +
              Number(multa) +
              Number(juros) +
              Number(correcao)) + ".00",
      rev: null,
      staDivAtiva: staDivAtiva,
      ajuiz: ``,
      cart: "",
      spc: "",
      staEmProtesto: staEmProtesto,
    })
  );

  const selectAll = (value: any) => {
    setCheckedAll(value);
    setChecked((prevState) => {
      let newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });

    if (value) {
      let vlr = valorPrincipal;
      let hon = honorarios;
      let geral = total;
      faturas.forEach((element) => {
        if (
          !listCheckbox.includes(element.idFatura) &&
          element.staEmProtesto === "N" &&
          (element.idTipoFat !== 1 ||
            (element.idTipoFat === 1 && element.idSituProcFatura !== 1))
        ) {
          listCheckbox.push(element.idFatura);
          faturasSelecionadas.push(element);
          vlr += Number(element.vlrEmissao);
          hon += element.honorario ? Number(element.honorario) : 0;
          geral += Number(element.vlrEmissao);
          geral += Number(element.multa);
          geral += Number(element.juros);
          geral += Number(element.correcao);
          geral += element.honorario ? Number(element.honorario) : 0;
        }
      });
      setValorPrincipal(vlr);
      setHonorarios(hon);
      setTotal(geral);
    } else {
      listCheckbox.splice(0, listCheckbox.length);
      faturasSelecionadas.splice(0, faturasSelecionadas.length);

      setValorPrincipal(0);
      setHonorarios(0);
      setTotal(0);
    }
    setCount(listCheckbox.length);
  };

  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  useEffect(() => {
    if (faturas) {
      let array = {};
      faturas.forEach((element) => {
        array = {
          ...array,
          [(element.idFatura as number).toString()]: false,
        };
      });
      setChecked(array);
    }
  }, [faturas]);

  const toggleCheck = (inputName, itens) => {
    const fatsMesmoVencimento = data.filter(
      (fat) =>
        fat.id === itens["id"] || fat.dtaVencimento === itens["dtaVencimento"]
    );

    if (fatsMesmoVencimento && fatsMesmoVencimento.length) {
      let qtdFatSel = 0;
      let valorItem = 0;
      let valorHonorario = 0;
      let juros = 0;
      let multa = 0;
      let cMon = 0;

      fatsMesmoVencimento.forEach((fatVenc) => {
        setChecked((prevState) => {
          const newState = { ...prevState };
          newState[fatVenc.id] = !prevState[fatVenc.id];
          return newState;
        });

        valorItem =
          valorItem +
          (Number(fatVenc["vlrEmissao"]) ? Number(fatVenc["vlrEmissao"]) : 0);

        valorHonorario =
          valorHonorario +
          (Number(fatVenc["honorario"]) ? Number(fatVenc["honorario"]) : 0);

        juros =
          juros + (Number(fatVenc["juros"]) ? Number(fatVenc["juros"]) : 0);

        multa =
          multa + (Number(fatVenc["multa"]) ? Number(fatVenc["multa"]) : 0);

        cMon = cMon + (Number(fatVenc["cMon"]) ? Number(fatVenc["cMon"]) : 0);

        if (!listCheckbox.includes(fatVenc.id)) {
          qtdFatSel++;
          listCheckbox.push(fatVenc.id);
          const [fat] = faturas.filter((item) => item.idFatura === fatVenc.id);
          faturasSelecionadas.push(fat);
        } else {
          qtdFatSel--;
          listCheckbox.splice(listCheckbox.indexOf(fatVenc.id), 1);
          const [fat] = faturas.filter((item) => item.idFatura === fatVenc.id);
          faturasSelecionadas.splice(faturasSelecionadas.indexOf(fat), 1);
        }
      });

      setCount(count + qtdFatSel);
      setValorPrincipal(valorPrincipal + valorItem * (qtdFatSel >= 0 ? 1 : -1));
      setHonorarios(honorarios + valorHonorario * (qtdFatSel >= 0 ? 1 : -1));
      setTotal(
        total +
          (valorItem + juros + multa + cMon + valorHonorario) *
            (qtdFatSel >= 0 ? 1 : -1)
      );
    } else {
      setChecked((prevState) => {
        const newState = { ...prevState };
        newState[inputName] = !prevState[inputName];
        return newState;
      });

      const valorItem = itens["vlrEmissao"];
      const valorHonorario = itens["honorario"];
      const juros = itens["juros"];
      const multa = itens["multa"];
      const cMon = itens["cMon"];

      if (!listCheckbox.includes(inputName)) {
        setCount(count + 1);
        listCheckbox.push(inputName);
        const [fat] = faturas.filter((item) => item.idFatura === inputName);
        faturasSelecionadas.push(fat);
        setValorPrincipal(valorPrincipal + Number(valorItem));
        setHonorarios(honorarios + Number(valorHonorario));
        setTotal(
          total +
            Number(valorItem) +
            Number(juros) +
            Number(multa) +
            Number(cMon) +
            Number(valorHonorario)
        );
      } else {
        setCount(count - 1);
        listCheckbox.splice(listCheckbox.indexOf(inputName), 1);
        const [fat] = faturas.filter((item) => item.idFatura === inputName);
        faturasSelecionadas.splice(faturasSelecionadas.indexOf(fat), 1);
        setValorPrincipal(Math.abs(valorPrincipal - Number(valorItem)));
        setHonorarios(Math.abs(honorarios - Number(valorHonorario)));
        setTotal(
          Math.abs(
            total -
              Number(valorItem) -
              Number(juros) -
              Number(multa) -
              Number(cMon) -
              Number(valorHonorario)
          )
        );
      }
    }
  };

  const handleGenerateGuiaReport = useCallback(async () => {
    if (faturasSelecionadas?.length > 0) {
      setLoadingReport(true);
      const idsFats = faturasSelecionadas.map((item: any) => item.idFatura);

      const response = await api.post(
        "agencia/gerar",
        {
          idUc: dadosUc.idUc,
          idCliente: dadosUc.idCliUsuario,
          faturas: idsFats,
        },
        {
          responseType: "blob",
        }
      );

      const file = new Blob([response.data], { type: "text/html" });
      const fileURL = URL.createObjectURL(file);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
      setLoadingReport(false);
    }
  }, [setLoadingReport, faturasSelecionadas, dadosUc]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("");
  };

  return (
    <>
      {message.length > 0 && (
        <>
          <div className={classes.root}>
            <Snackbar
              open={!!message}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alert}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </>
      )}

      <Card className={classes.card}>
        <TableContainer>
          <Table>
            <TableHead className={classes.tableHead}>
              <Typography className={classes.headerTitle}>
                Faturas em Aberto
              </Typography>
              <Typography className={classes.header}>
                <text className={classes.alignIcon}>
                  <ApartmentIcon />
                </text>{" "}
                {dadosUc
                  ? "UNID. CONS.:" +
                    dadosUc.idUc +
                    " - " +
                    dadosUc.idTipoLogradouro +
                    " " +
                    dadosUc.nomeLogradouro +
                    " " +
                    (dadosUc.numImovel ? dadosUc.numImovel : "")
                  : ""}{" "}
              </Typography>
              <Typography className={classes.header}>
                <text className={classes.alignIcon}>
                  <PersonIcon />
                </text>{" "}
                USUÁRIO: {dadosUc?.nomeCliente}
              </Typography>
              <Typography className={classes.header}></Typography>
            </TableHead>
            <div className={classes.body}>
              <StyledTableRow>
                {header.map(
                  (
                    item: { title: React.ReactNode },
                    index: string | number | undefined
                  ) => (
                    <TableCell className={classes.cellHeader} key={index}>
                      {item.title}
                    </TableCell>
                  )
                )}
              </StyledTableRow>
              {data &&
                data?.map((itens, index) => (
                  <StyledTableRow
                    className={classes.tableRow}
                    hover
                    key={index}
                  >
                    <TableCell key={index} className={classes.cell}>
                      {itens.staEmProtesto === "S" ? (
                        <Tooltip
                          className={classes.tooltipInconsis}
                          title={
                            "Esta fatura está em protesto. Para quitá-la, procure um posto de atendimento."
                          }
                        >
                          <ReportProblemRounded />
                        </Tooltip>
                      ) : (
                        <input
                          type="checkbox"
                          name={itens["id"]}
                          onChange={() => toggleCheck(itens["id"], itens)}
                          checked={
                            listCheckbox?.filter(
                              (element) => element === itens["id"]
                            ).length > 0
                              ? true
                              : false
                          }
                        />
                      )}
                    </TableCell>
                    {Object.entries(itens).map(([key, value]) => (
                      <>
                        {key !== "id" &&
                          key !== "idTipoFat" &&
                          key !== "idSituProcFatura" &&
                          key !== "staEmProtesto" && (
                            <>
                              {key === "endereco" && (
                                <>
                                  <TableCell key={key} className={classes.cell}>
                                    <Tooltip
                                      title={itens["tooltip"]}
                                      aria-label={itens["tooltip"]}
                                    >
                                      <ErrorOutlineIcon
                                        className={classes.alignIconAlert}
                                      />
                                    </Tooltip>{" "}
                                    {itens[key]}
                                  </TableCell>
                                </>
                              )}
                              {key !== "endereco" &&
                                key !== "tooltip" &&
                                key !== "staEmProtesto" &&
                                (key === "mesRefFat" ||
                                  key === "abreviacao" ||
                                  key === "dtaVencimento") && (
                                  <TableCell
                                    align="right"
                                    key={key}
                                    className={classes.cell}
                                  >
                                    {itens[key]}
                                  </TableCell>
                                )}

                              {key !== "endereco" &&
                                key !== "tooltip" &&
                                key !== "mesRefFat" &&
                                key !== "abreviacao" &&
                                key !== "dtaVencimento" &&
                                key !== "staEmProtesto" && (
                                  <TableCell
                                    align="right"
                                    key={key}
                                    className={classes.cellValores}
                                  >
                                    {itens[key]}
                                  </TableCell>
                                )}
                            </>
                          )}
                      </>
                    ))}
                  </StyledTableRow>
                ))}
            </div>
          </Table>

          <div className={classes.divider} />

          <Typography className={classes.total}>
            Selecionadas: {count}
          </Typography>
          <Typography className={classes.total}>
            Valor Principal:{" "}
            {valorPrincipal.toString().includes(".")
              ? "R$ " + valorPrincipal.toFixed(2)
              : "R$ " + valorPrincipal + ".00"}
          </Typography>
          <Typography className={classes.total}>
            Honorários:{" "}
            {honorarios.toString().includes(".")
              ? "R$ " + honorarios.toFixed(2)
              : "R$ " + honorarios + ".00"}
          </Typography>
          <Typography className={classes.total}>
            Total Devido:{" "}
            {total.toString().includes(".")
              ? "R$ " + total?.toFixed(2)
              : "R$ " + total + ".00"}
          </Typography>
          <div className={classes.buttonsDiv}>
            <div className={classes.line}>
              <Button
                variant="contained"
                className={classes.buttons}
                onClick={handleGenerateGuiaReport}
              >
                {" "}
                Gerar guia para pagamento
              </Button>
            </div>
          </div>
        </TableContainer>
      </Card>

      <Backdrop className={classes.backdrop} open={loadingReport}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Faturas;

const formatReference = (date: string) => {
  return date?.substring(5, 7) + "/" + date?.substring(0, 4);
};

export const formatDateWithoutHours = (date: string) => {
  if (date) {
    return (
      date?.substring(8, 10) +
      "/" +
      date?.substring(5, 7) +
      "/" +
      date?.substring(0, 4)
    );
  } else {
    return "";
  }
};
